<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-text-field
          solo
          dense
          placeholder="Keresés névre, email címre"
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        v-for="jog in jogosultsag"
        :key="jog.value"
        class="text-center"
      >
        {{ jog.text }}: {{ letszam(jog.value) }}
      </v-col>
    </v-row>
    <div class="d-flex justify-space-around flex-wrap">
      <v-card
        v-for="tag in szurtTagok"
        :key="tag.recno"
        class="ma-2"
        width="400"
        color="rgba(255,255,255,0.4)"
        style="backdrop-filter: blur(30px)"
        hover
        outlined
      >
        <v-card-title>
          {{ tag.nev }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-row dense>
                <v-col cols="12">{{ tag.email }}</v-col>
                <v-col cols="12">{{ tag.telefon }}</v-col>
                <v-col cols="12">{{ jogNev(tag.jogok) }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="4" align="end" class="fill-height">
              <v-avatar size="100">
                <img :src="kep(tag.recno)" />
              </v-avatar>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row dense>
              <v-col cols="9">
                <v-icon @click="modiTag(tag)" v-if="vanJoga('admin')">
                  mdi-account-edit-outline
                </v-icon>
                <v-icon
                  @click="deleteTag(tag)"
                  class="ml-3"
                  v-if="vanJoga('admin')"
                >
                  mdi-delete
                </v-icon>
              </v-col>
              <v-col cols="3">
                <div
                  style="position: absolute; bottom: 18px; right: 10px"
                  v-if="vanJoga('admin') || tag.recno == $store.state.userid"
                >
                  <v-file-input
                    prepend-icon="mdi-camera"
                    hide-input
                    hide-details
                    dense
                    @change="foto(tag.recno)"
                    accept="image/png, image/jpeg, image/bmp"
                    v-model="photos"
                  >
                  </v-file-input>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <v-icon
        v-if="vanJoga('admin')"
        style="position: fixed; bottom: 10px; right: 10px"
        color="green"
        size="40px"
        @click="newTag()"
      >
        mdi-account-plus-outline
      </v-icon>

      <v-dialog v-model="tagDialog" max-width="600">
        <v-card>
          <v-toolbar color="green" dark
            >{{ formTitle }}<v-spacer></v-spacer
            ><v-icon
              @click="
                tagDialog = false;
                formData = {};
              "
              >mdi-close</v-icon
            ></v-toolbar
          >
          <v-card-text>
            <v-form v-model="tagForm" class="mt-6">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    regular
                    label="Név"
                    :rules="[(value) => !!value || 'Kötelező kitölteni!']"
                    v-model="formData.nev"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    regular
                    label="E-mail"
                    :rules="[
                      (v) =>
                        /.+@.+\..+/.test(v) || !v || 'Helyes e-mail címet írj!',
                    ]"
                    v-model="formData.email"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    regular
                    label="Telefon"
                    v-model="formData.telefon"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    dense
                    :items="jogosultsag"
                    label="Jogosultsági szint"
                    :rules="[(value) => !!value || 'Kötelező kitölteni!']"
                    v-model="formData.jogok"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" v-if="formData.recno">
                  <v-text-field
                    type="password"
                    dense
                    regular
                    label="Új jelszó (ha nincs kitöltve, a jelszó nem módosul)"
                    v-model="formData.jelszo"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12"></v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-file-input
              class="mb-5 ml-2"
              prepend-icon="mdi-camera"
              hide-input
              :disabled="!formData.recno"
              @change="foto(formData.recno)"
              accept="image/png, image/jpeg, image/bmp"
              v-model="photos"
            >
            </v-file-input>
            <v-btn text @click="mentTag()" :disabled="!tagForm">Ment</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ErrorDialog v-model="errorMessage"></ErrorDialog>
      <Confirm ref="confirm"></Confirm>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { serialize } from "object-to-formdata";
export default {
  components: {},
  name: "Tagok",
  data() {
    return {
      photos: [],
      tagok: [],
      tagForm: false,
      errorMessage: "",
      tagDialog: false,
      jogosultsag: [
        { value: "admin", text: "Adminisztrátor" },
        { value: "tag", text: "Klubtag" },
        { value: "vendeg", text: "Vendég" },
      ],
      tagFormAction: "",
      formData: {},
      search: "",
    };
  },
  computed: {
    szurtTagok() {
      let vm = this;

      if (this.search) {
        return this.tagok.filter(function (item) {
          return (
            item.nev.toLowerCase().indexOf(vm.search.toLowerCase()) > -1 ||
            item.email.toLowerCase().indexOf(vm.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.tagok;
      }
    },
    formTitle() {
      return this.tagFormAction == "new"
        ? "Új tag felvétele"
        : "Tag adatainak módosítása";
    },
  },
  mounted() {
    this.getTagok();
  },
  methods: {
    foto(user_rn) {
      const form_data = serialize({
        photos: this.photos,
        action: "kep_upload",
        user_rn: user_rn,
      });
      let vm = this;
      axios({
        method: "post",
        url: "https://darts.laryon.hu/backend/data.php",
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.getTagok();
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    letszam(jog) {
      return this.tagok.filter(function (item) {
        return item.jogok == jog;
      }).length;
    },
    deleteTag(tag) {
      let vm = this;

      let formData = new FormData();

      formData.append("action", "deleteTag");
      formData.append("recno", tag.recno);
      this.$refs.confirm

        .open(
          "Tag törlése",

          "Biztosan törli " + tag.nev + " tagot?"
        )
        .then((confirm) => {
          if (confirm) {
            axios
              .post("https://darts.laryon.hu/backend/data.php", formData)
              .then(function (response) {
                if (response.data.error) {
                  vm.errorMessage = response.data.error;
                } else {
                  vm.errorMessage = "";
                  vm.tagok = response.data;
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          }
        });
    },
    jogNev(jog) {
      let x = this.jogosultsag.find(function (item) {
        return item.value == jog;
      });
      if (x) {
        return x.text;
      }
    },

    kep(recno) {
      return (
        "https://darts.laryon.hu/images/photos/" +
        recno +
        ".jpg" +
        "?" +
        new Date()
      );
    },
    newTag() {
      this.tagFormAction = "new";
      this.tagDialog = true;
    },
    modiTag(tag) {
      this.formData = {};
      this.formData.nev = tag.nev;
      this.formData.email = tag.email;
      this.formData.telefon = tag.telefon;
      this.formData.jogok = tag.jogok;
      this.formData.recno = tag.recno;

      this.tagFormAction = "modi";
      this.tagDialog = true;
    },
    mentTag() {
      let vm = this;
      let formData = new FormData();

      Object.entries(this.formData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("action", "mentTag");

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.tagDialog = false;
            vm.formData = {};
            vm.tagok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getTagok() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getTagok");
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.tagok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style>
</style>