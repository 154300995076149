<template>
  <div>
    <div class="d-flex" :style="nylegwidth">
      <div
        v-for="kor in korok"
        :key="kor"
        style="width: 200px"
        :class="nyertlegclass(kor)"
      >
        <v-text-field
          outlined
          dense
          :label="`Nyert leg ` + kor + `. kör`"
          v-model="nyertleg[kor]"
          @change="mentNyertLegek"
          :disabled="vanNyert(kor) || !vanJoga('admin')"
        ></v-text-field>
      </div>
    </div>
    <bracket :flat-tree="rounds" @onClikkedBracket="clickBracket">
      <template slot="player" slot-scope="{ player }">
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <span>{{ player.name }}&nbsp;</span>
          <span v-if="player.name"> {{ player.eredmeny }}</span>
        </div>
      </template>
    </bracket>
    <v-dialog
      v-model="eredmenyDialog"
      max-width="600"
      v-if="selectedBracket.player1"
    >
      <v-card>
        <v-toolbar color="green" dark>
          Eredmény megadása<v-spacer> </v-spacer>
          <v-icon @click="eredmenyDialog = false">mdi-close</v-icon></v-toolbar
        >
        <v-card-text class="pt-5 text-center">
          <v-row>
            <v-col class="text-h5">{{ selectedBracket.player1.name }}</v-col>
            <v-col class="text-h5">{{ selectedBracket.player2.name }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="4" offset="1" sm="2" offset-sm="2">
              <input
                v-if="selectedBracket.player1.name"
                v-model="jatekos1_eredmeny"
                class="er"
                @change="mentEredmeny(1)"
                onfocus="this.select();"
                type="number"
              />
            </v-col>

            <v-col cols="4" offset="2" sm="2" offset-sm="4">
              <input
                v-if="selectedBracket.player2.name"
                v-model="jatekos2_eredmeny"
                class="er"
                @change="mentEredmeny(2)"
                onfocus="this.select();"
                type="number"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ErrorDialog v-model="errorMessage"></ErrorDialog>
  </div>
</template>

<script>
import Bracket from "vue-tournament-bracket";
import axios from "axios";

function getBaseLog(x, y) {
  return Math.round(Math.log(y) / Math.log(x));
}

export default {
  components: {
    Bracket,
  },
  name: "Fotabla",
  props: {
    meret: {
      type: Number,
      required: true,
    },
    versenyAllapot: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rounds: [],
      eredmenyDialog: false,
      selectedBracket: {},
      errorMessage: "",
      jatekos1_eredmeny: null,
      jatekos2_eredmeny: null,
      nyertleg: [],
    };
  },
  mounted() {
    //this.generate();
    //this.getFotabla();
  },
  computed: {
    nylegwidth() {
      let width = this.korok * 200 + 100;
      return "width:" + width + "px";
    },
    korok() {
      if (this.meret) {
        return Math.round(getBaseLog(2, this.meret));
      } else {
        return 0;
      }
    },
  },
  watch: {
    meret() {
      this.generate();
      this.getFotabla();
      if (this.$store.state.verseny) {
        this.nyertleg = this.$store.state.verseny.fotablanyertlegek.split(",");
      }
    },
    "$store.state.rebuild": function () {
      this.generate();
      this.getFotabla();
      if (this.$store.state.verseny) {
        this.nyertleg = this.$store.state.verseny.fotablanyertlegek.split(",");
      }
    },
  },

  methods: {
    vanNyert(kor) {
      let minid = 1,
        maxid,
        kormeret = this.meret;
      for (let i = 1; i <= kor; i++) {
        if (i == 1) {
          minid = 1;
        } else {
          minid = maxid + 1;
        }
        kormeret = kormeret / 2;
        maxid = minid + kormeret - 1;
      }

      let gyoztesmeccs = this.rounds.find(function (item) {
        return (
          item.id >= minid &&
          item.id <= maxid &&
          (item.player1.winner || item.player2.winner)
        );
      });

      return !!gyoztesmeccs;
    },
    mentNyertLegek() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "mentNyertLegek");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("nyertlegek", this.nyertleg.join(","));

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    nyertlegclass(kor) {
      if (kor > 1) {
        return "ml50";
      }
    },
    getKorFromId(id) {
      //  console.log("id: " + id);
      let j = 1;
      for (let i = this.meret / 2; i >= 1; i = i + i / 2) {
        if (id <= i) {
          return j;
        }
        j++;
      }
      return 0;
    },

    tovabbjuttat() {
      let round_next_id = this.selectedBracket.next;
      let vege = !round_next_id ? true : false;
      let round_id = this.selectedBracket.id;
      let kor = this.getKorFromId(this.selectedBracket.id); //this.selectedBracket.title.split(" ")[1];
      let player;

      if (this.selectedBracket.player1.eredmeny == this.nyertleg[kor]) {
        if (
          this.selectedBracket.player1.eredmeny !=
          this.selectedBracket.player2.eredmeny
        ) {
          player = this.selectedBracket.player1;
          this.fotablaNyert(player.id, round_id, vege);
        } else {
          this.errorMessage = "Döntetlen végeredmény nem lehet!";
        }
      }
      if (this.selectedBracket.player2.eredmeny == this.nyertleg[kor]) {
        if (
          this.selectedBracket.player1.eredmeny !=
          this.selectedBracket.player2.eredmeny
        ) {
          player = this.selectedBracket.player2;
          this.fotablaNyert(player.id, round_id, vege);
        } else {
          this.errorMessage = "Döntetlen végeredmény nem lehet!";
        }
      }

      let round_next = this.rounds.find(function (item) {
        return item.id == round_next_id;
      });
      if (round_next && player) {
        this.fotabla_tarol(player.id, round_next_id, ((round_id + 1) % 2) + 1);
      }
    },
    fotabla_tarol(user_rn, tabla_ssz, jatekos) {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "fotablaTarol");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("user_rn", user_rn);
      formData.append("tabla_ssz", tabla_ssz);
      formData.append("jatekos", jatekos);
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.feltoltFotabla(response.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    fotablaNyert(user_rn, tabla_ssz, vege) {
      if (vege) {
        this.$emit("allapotValt", "vege");
      }

      let vm = this;
      let formData = new FormData();

      formData.append("action", "fotablaNyert");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("user_rn", user_rn);
      formData.append("tabla_ssz", tabla_ssz);
      formData.append("vege", vege);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.feltoltFotabla(response.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    ellenoriz() {
      let ok = true;
      let kor = this.getKorFromId(this.selectedBracket.id); //parseInt(this.selectedBracket.title.split(" ")[1]) + 1;

      if (
        this.jatekos1_eredmeny > this.nyertleg[kor] ||
        this.jatekos2_eredmeny > this.nyertleg[kor]
      ) {
        this.errorMessage =
          "A meccsek " + this.nyertleg[kor] + " nyert leg-ig tartanak!! ";
        ok = false;
      }

      if (ok) {
        this.selectedBracket.player1.eredmeny = this.jatekos1_eredmeny;
        this.selectedBracket.player2.eredmeny = this.jatekos2_eredmeny;
      }

      return ok;
    },
    async mentEredmeny() {
      if (this.ellenoriz()) {
        let vm = this;
        let formData = new FormData();

        formData.append("action", "fotablaEredmenyMent");
        formData.append("verseny_rn", this.$store.state.selected_verseny);
        formData.append("tabla_ssz", this.selectedBracket.id);
        formData.append(
          "jatekos1_eredmeny",
          this.selectedBracket.player1.eredmeny
        );
        formData.append(
          "jatekos2_eredmeny",
          this.selectedBracket.player2.eredmeny
        );
        await axios
          .post("https://darts.laryon.hu/backend/data.php", formData)
          .then(function (response) {
            if (response.data.error) {
              vm.errorMessage = response.data.error;
            } else {
              vm.feltoltFotabla(response.data);
            }
          })
          .catch(function (error) {
            console.error(error);
          });
        this.tovabbjuttat();
      }
    },
    feltoltFotabla(adat) {
      // console.log(adat);
      if (adat) {
        adat.forEach((element) => {
          let round = this.rounds.find(function (item) {
            return item.id == element.tabla_ssz;
          });
          if (round) {
            round.player1.id = element.jatekos1;
            round.player1.name = element.j1_nev;
            if (element.jatekos1 == element.nyert) {
              //round.player1.winner = true;
              //round.player2.winner = false;
              this.$set(round.player1, "winner", true);
              this.$set(round.player2, "winner", false);
            }

            this.$set(round.player1, "eredmeny", element.jatekos1_eredmeny);

            round.player2.id = element.jatekos2;
            round.player2.name = element.j2_nev;
            if (element.jatekos2 == element.nyert) {
              //   round.player2.winner = true;
              //   round.player1.winner = false;
              this.$set(round.player2, "winner", true);
              this.$set(round.player1, "winner", false);
            }

            this.$set(round.player2, "eredmeny", element.jatekos2_eredmeny);
          }
        });
      }
    },
    getFotabla() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getFotabla");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.feltoltFotabla(response.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    generate() {
      let game = {
        player1: { id: 0, name: "" },
        player2: { id: 0, name: "" },
      };

      let ro = [];
      let r = getBaseLog(2, this.meret);
      let meret = this.meret / 2;
      this.rounds = [];
      let g = 1;
      let bm = 0;
      for (let j = 0; j <= r; j++) {
        bm = bm + meret;
        for (let i = 1; i <= meret; i++) {
          game.id = g;
          game.next = bm + (i + (i % 2)) / 2;
          if (game.next == this.meret) {
            game.next = "";
          }
          g++;
          ro.push(JSON.parse(JSON.stringify(game)));
        }
        meret = meret / 2;
      }
      this.rounds = ro;
    },
    clickBracketForce(br) {
      this.clickBracket(br, true);
    },
    clickBracket(br, force = true) {
      // console.log("kor: " + this.getKorFromId(br.id));
      if (this.vanJoga("admin")) {
        //console.log("itt");
        this.selectedBracket = br;
        if (this.versenyAllapot == "vege") {
          this.errorMessage = "A versenynek már vége!";

          return;
        }
        if (this.versenyAllapot != "fotabla") {
          this.errorMessage = "Még nem zárultak le a csoportmérközések!";

          return;
        }
        if (!this.nyertleg[this.getKorFromId(br.id)]) {
          this.errorMessage = "Meg kelladnia a kör nyert legjeinek számát!";
          return;
        }
        if (
          this.getKorFromId(br.id) > 1 &&
          (br.player1.id == 0 || br.player2.id == 0)
        ) {
          this.errorMessage = "Még nincs meg minkét játékos!";
          return;
        }

        if (br.player1.winner != !br.player2.winner || force) {
          this.jatekos1_eredmeny = br.player1.eredmeny;
          this.jatekos2_eredmeny = br.player2.eredmeny;
          this.eredmenyDialog = true;
        }
      }
    },
  },
};
</script>

<style>
.vtb-item-players {
  width: 200px;
}
.vtb-item-players .winner.highlight {
  xbackground-color: rgba(228, 230, 228, 0.472) !important;
  color: blue;
}
.vtb-item-players .winner {
  background-color: rgba(13, 148, 13, 0.472) !important;
}

.vtb-item-players .defeated.highlight {
  xbackground-color: rgba(228, 230, 228, 0.472) !important;
  color: blue;
}
.vtb-item-players .defeated {
  background-color: rgba(255, 0, 0, 0.644) !important;
}
.vtb-item-players {
  background-color: transparent !important;
}
.vtb-item-players .vtb-player1 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(0, 30, 200, 0.4);
}

.vtb-item-players .vtb-player2 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(0, 30, 200, 0.3);
}
.er {
  width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 30px;
  text-align: center;
  border: 1px solid rgb(145, 143, 143);
  border-radius: 5px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ml50 {
  margin-left: 50px;
}
</style>