var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-3",attrs:{"height":"calc(100vh - 75px)"}},[_c('h1',{staticClass:"green--text"},[_vm._v("Vesrenysorozatok")]),_c('v-data-table',{staticClass:"row-pointer",attrs:{"dense":"","hide-default-footer":"","headers":_vm.idoszakok_header,"items":_vm.idoszakok,"item-key":"recno","single-select":"","height":"calc(100vh - 175px)","header-props":_vm.headerProps,"sort-by":['datum_tol'],"sort-desc":[true]},on:{"click:row":_vm.selectidoszak},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.vanJoga('admin'))?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.editidoszak(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}]),model:{value:(_vm.selected_idoszak),callback:function ($$v) {_vm.selected_idoszak=$$v},expression:"selected_idoszak"}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.idoszakDialog),callback:function ($$v) {_vm.idoszakDialog=$$v},expression:"idoszakDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"green","dark":""}},[_vm._v(_vm._s(_vm.formTitle)),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.idoszakDialog = false;
            _vm.formData = {};}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-form',{ref:"f1",staticClass:"mt-6",model:{value:(_vm.idoszakForm),callback:function ($$v) {_vm.idoszakForm=$$v},expression:"idoszakForm"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"regular":"","label":"A versenysorozat neve","rules":[_vm.kotelezoRule]},model:{value:(_vm.formData.megnevezes),callback:function ($$v) {_vm.$set(_vm.formData, "megnevezes", $$v)},expression:"formData.megnevezes"}})],1),_c('v-col',{staticClass:"mr-0",attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"A versenysorozat kezdő napja","rules":[_vm.kotelezoRule, _vm.idoszakRule],"readonly":""},model:{value:(_vm.formData.datum_tol),callback:function ($$v) {_vm.$set(_vm.formData, "datum_tol", $$v)},expression:"formData.datum_tol"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDatumtol),callback:function ($$v) {_vm.menuDatumtol=$$v},expression:"menuDatumtol"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDatumtol = false}},model:{value:(_vm.formData.datum_tol),callback:function ($$v) {_vm.$set(_vm.formData, "datum_tol", $$v)},expression:"formData.datum_tol"}})],1)],1),_c('v-col',{staticClass:"mr-0",attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"A versenysorozat utolsó napja","rules":[_vm.kotelezoRule, _vm.idoszakRule],"readonly":""},model:{value:(_vm.formData.datum_ig),callback:function ($$v) {_vm.$set(_vm.formData, "datum_ig", $$v)},expression:"formData.datum_ig"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDatumig),callback:function ($$v) {_vm.menuDatumig=$$v},expression:"menuDatumig"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDatumig = false}},model:{value:(_vm.formData.datum_ig),callback:function ($$v) {_vm.$set(_vm.formData, "datum_ig", $$v)},expression:"formData.datum_ig"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","disabled":!_vm.idoszakForm},on:{"click":function($event){return _vm.mentidoszak()}}},[_vm._v("Ment")])],1)],1)],1),(_vm.vanJoga('admin'))?_c('v-icon',{staticStyle:{"position":"fixed","bottom":"20px","right":"15px"},attrs:{"color":"green","size":"50px"},on:{"click":function($event){return _vm.newidoszak()}}},[_vm._v(" mdi-plus-circle-outline ")]):_vm._e(),_c('error-dialog',{model:{value:(_vm.errorMessage),callback:function ($$v) {_vm.errorMessage=$$v},expression:"errorMessage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }