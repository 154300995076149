import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import Versenyek from '../components/Versenyek.vue'
import Verseny from '../components/Verseny.vue'
import Idoszakok from '../components/Idoszakok.vue'
import Dashboard from '../components/Dashboard.vue'
import Tagok from '../components/Tagok.vue'
import Stat from '../components/Stat.vue'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/versenyek',
    name: 'versenyek',
    component: Versenyek,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/idoszakok',
    name: 'idoszakok',
    component: Idoszakok,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/verseny',
    name: 'verseny',
    component: Verseny,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tagok',
    name: 'tagok',
    component: Tagok,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stat',
    name: 'stat',
    component: Stat,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.loggedIn) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }

});

export default router
