<template>
  <div>
    <v-card
      flat
      class="d-flex flex-row pa-2 justify-space-between green white--text"
    >
      <div>{{ verseny.megnevezes }}</div>
      <div>{{ verseny.datum }} {{ verseny.idopont }}</div>
      <div>{{ verseny.helyszin }}</div>
    </v-card>

    <v-tabs
      v-model="tab"
      align-with-title
      class="mt-3"
      fixed-tabs
      color="green"
    >
      <v-tabs-slider color="green"></v-tabs-slider>

      <v-tab> Indulók </v-tab>
      <v-tab> Csoportverseny </v-tab>
      <v-tab> Főtábla </v-tab>
      <v-tab> Végeredmény </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item eager>
        <v-card flat style="height: calc(100vh - 175px); overflow: auto">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-data-table
                  dense
                  hide-default-footer
                  :headers="indulok_header"
                  :items="indulok_k"
                  v-model="selected_indulok"
                  item-key="recno"
                  :single-select="false"
                  :show-select="versenyAllapot == 'indulok' && vanJoga('admin')"
                  :items-per-page="-1"
                  fixed-header
                  @item-selected="selectIndulo"
                  height="calc(100vh - 210px)"
                >
                  <template v-slot:item.nev="{ item }">
                    <span :class="item.jogok == 'random' ? 'green--text' : ''">
                      {{ item.nev }}
                    </span>
                  </template>
                  <template v-slot:item.kiemelt="{ item }">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="item.kiemelt"
                      dense
                      v-if="
                        ((versenyAllapot == 'indulok' && vanJoga('admin')) ||
                          item.kiemelt) &&
                        csoportDb > 1
                      "
                      :disabled="
                        versenyAllapot != 'indulok' || !vanJoga('admin')
                      "
                      label=""
                      color="success"
                      hide-details
                      @change="selectKiemelt(item)"
                    ></v-switch>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-card>
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        class="text-h2"
                        cols="12"
                        sm="9"
                        md="9"
                        lg="9"
                        xl="9"
                        >{{ induloDb }} induló</v-col
                      >
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-icon size="64" color="green"> mdi-account </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-5">
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        class="text-h2"
                        cols="12"
                        sm="9"
                        md="9"
                        lg="9"
                        xl="9"
                      >
                        {{ csoportDb }} csoport</v-col
                      >
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-icon size="64" color="green">
                          mdi-account-multiple
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="mt-5">
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        class="text-h2"
                        cols="12"
                        sm="9"
                        md="9"
                        lg="9"
                        xl="9"
                      >
                        {{ fotablaDb }}-s főtábla
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-icon size="64" color="green">
                          mdi-tournament
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      v-if="versenyAllapot == 'indulok' && vanJoga('admin')"
                      text
                      color="green"
                      class="mt-8"
                      @click="allapotValt('csoportVerseny')"
                    >
                      Jelentkezés lezárása, csoporttagok sorsolása
                    </v-btn>
                  </v-col>
                </v-row>

                <v-btn
                  v-if="versenyAllapot == 'indulok' && vanJoga('admin')"
                  text
                  color="green"
                  class="mt-8"
                  @click="randomJatekos()"
                >
                  Random játékos felvétele
                </v-btn>
                <v-row v-if="versenyAllapot != 'indulok'">
                  <v-col class="text-h6 mt-6 ml-4 red--text"
                    >A jelentkezés lezárult</v-col
                  >
                </v-row>
                <v-btn
                  v-if="versenyAllapot == 'csoportVerseny' && vanJoga('admin')"
                  text
                  color="green"
                  class="mt-8"
                  @click="lezarasVissza()"
                >
                  Lezárás visszavonása
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item eager>
        <v-card flat>
          <v-card-text>
            <CsoportVerseny
              :csoportszam="csoportDb"
              :induloszam="induloDb"
              :versenyAllapot="versenyAllapot"
              @allapotValt="allapotValt"
              :kijuto="kijutoDb"
            >
            </CsoportVerseny>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item eager>
        <v-card
          flat
          height="calc(100vh - 175px)"
          style="overflow: auto !important"
        >
          <v-card-text>
            <Fotabla
              :meret="fotablaDb"
              :versenyAllapot="versenyAllapot"
              @allapotValt="allapotValt"
            ></Fotabla>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item eager>
        <v-card
          flat
          height="calc(100vh - 175px)"
          style="overflow: auto !important"
        >
          <v-card-text v-if="versenyAllapot == 'vege'">
            <Vegeredmeny :versenyAllapot="versenyAllapot"></Vegeredmeny>
          </v-card-text>
        </v-card>
        <v-btn
          text
          color="green"
          style="position: absolute; bottom: 10px; right: 5px"
          @click="excel()"
        >
          Excel export
        </v-btn>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogRandom" max-width="400">
      <v-card>
        <v-toolbar color="green" dark
          >Random játékos felvétele<v-spacer></v-spacer
          ><v-icon
            @click="
              dialogRandom = false;
              randomNev = '';
            "
            >mdi-close</v-icon
          ></v-toolbar
        >
        <v-card-text>
          <v-form class="mt-6" ref="f1">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  regular
                  label="A játékos neve"
                  v-model="randomNev"
                  @keyup.enter="randomHozzaad()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end"> </v-card-actions>
      </v-card>
    </v-dialog>
    <Confirm ref="confirm"></Confirm>
    <ErrorDialog v-model="errorMessage"></ErrorDialog>
  </div>
</template>

<script>
import Fotabla from "./Fotabla.vue";
import CsoportVerseny from "./CsoportVerseny.vue";
import Vegeredmeny from "./Vegeredmeny.vue";

import axios from "axios";
export default {
  name: "verseny",
  components: { Fotabla, CsoportVerseny, Vegeredmeny },

  data() {
    return {
      dialogRandom: false,
      randomNev: "",
      tab: null,
      errorMessage: "",
      verseny: {},
      indulok: [],
      selected_indulok: [],
      indulok_header: [
        {
          text: "Név",
          value: "nev",
        },
        {
          text: "Kiemelt",
          value: "kiemelt",
        },
      ],
      besorolas: [
        { min: 1, max: 7, csoport: 1, kijuto: 0, fotabla: 0 },
        { min: 8, max: 8, csoport: 2, kijuto: 2, fotabla: 4 },
        { min: 9, max: 9, csoport: 2, kijuto: 3, fotabla: 8 },
        { min: 10, max: 12, csoport: 2, kijuto: 4, fotabla: 8 },
        { min: 13, max: 16, csoport: 4, kijuto: 2, fotabla: 8 },
        { min: 17, max: 19, csoport: 4, kijuto: 3, fotabla: 16 },
        { min: 20, max: 24, csoport: 4, kijuto: 4, fotabla: 16 },
        { min: 25, max: 32, csoport: 8, kijuto: 2, fotabla: 16 },
        { min: 33, max: 39, csoport: 8, kijuto: 3, fotabla: 32 },
        { min: 40, max: 48, csoport: 8, kijuto: 4, fotabla: 32 },
        { min: 49, max: 64, csoport: 16, kijuto: 2, fotabla: 32 },
        { min: 65, max: 79, csoport: 16, kijuto: 3, fotabla: 64 },
      ],
      versenyAllapot: "indulok",
    };
  },
  computed: {
    indulok_k() {
      if (this.versenyAllapot == "indulok" && this.vanJoga("admin")) {
        return this.indulok;
      } else {
        return this.selected_indulok;
      }
    },
    induloDb() {
      return this.selected_indulok.length;
    },
    csoportDb() {
      let vm = this;
      let x = this.besorolas.find(function (item) {
        return item.min <= vm.induloDb && item.max >= vm.induloDb;
      });
      if (x) {
        return x.csoport;
      } else {
        return 0;
      }
    },
    fotablaDb() {
      let vm = this;
      let x = this.besorolas.find(function (item) {
        return item.min <= vm.induloDb && item.max >= vm.induloDb;
      });
      if (x) {
        return x.fotabla;
      } else {
        return 0;
      }
    },
    kijutoDb() {
      let vm = this;
      let x = this.besorolas.find(function (item) {
        return item.min <= vm.induloDb && item.max >= vm.induloDb;
      });
      if (x) {
        return x.kijuto;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    this.getVerseny();
    this.getIndulok();
  },
  methods: {
    lezarasVissza() {
      let vm = this;

      let formData = new FormData();

      formData.append("action", "lezarasVissza");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      this.$refs.confirm

        .open(
          "Lezárás visszavonása",

          "Biztosan visszavonod a lezárást, és ezzel törlöd a csoport sorsolást?"
        )
        .then((confirm) => {
          if (confirm) {
            axios
              .post("https://darts.laryon.hu/backend/data.php", formData)
              .then(function (response) {
                if (response.data.error) {
                  vm.errorMessage = response.data.error;
                } else {
                  vm.versenyAllapot = "indulok";
                  vm.errorMessage = "";
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          }
        });
    },
    randomHozzaad() {
      console.log(this.formData);
      let vm = this;
      let formData = new FormData();

      formData.append("action", "mentTag");
      formData.append("nev", this.randomNev);
      formData.append("jogok", "random");
      formData.append("verseny_rn", this.$store.state.selected_verseny);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.getIndulok();
          }
        })
        .catch(function (error) {
          console.error(error);
        });
      this.randomNev = "";
    },
    randomJatekos() {
      this.dialogRandom = true;
    },
    excel() {
      let vm = this;
      let formData = new FormData();

      formData.append("type", "vegeredmeny");
      formData.append("verseny_rn", this.$store.state.selected_verseny);

      axios
        .post("https://darts.laryon.hu/backend/excel.php", formData)
        .then(function (response) {
          vm.downloadFromUrl(response.data.url);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    allapotValt(allapot) {
      if (allapot == "csoportVerseny") {
        let kiemeltdb = this.selected_indulok.filter(function (item) {
          return item.kiemelt;
        }).length;

        if (kiemeltdb != this.csoportDb && kiemeltdb > 0) {
          this.errorMessage =
            "Annyi kiemelt játékos lehet mint ahány csoport lesz!!";
          return;
        }
        if (this.induloDb < 2) {
          this.errorMessage = "Minimum 2 induló szükséges!!";
          return;
        }
      }

      let vm = this;
      let formData = new FormData();

      formData.append("action", "setAllapot");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("allapot", allapot);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.versenyAllapot = allapot;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    selectKiemelt(item) {
      if (item.kiemelt) {
        this.setIndulo(item.recno, true, true);
      } else {
        this.setIndulo(item.recno, true, false);
      }
    },
    selectIndulo(param) {
      this.setIndulo(param.item.recno, param.value);
    },
    setIndulo(user_rn, ertek, kiemelt = false) {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "setIndulo");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("user_rn", user_rn);
      formData.append("ertek", ertek);
      formData.append("kiemelt", kiemelt);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.getIndulok();
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getVerseny() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getVerseny");
      formData.append("recno", this.$store.state.selected_verseny);
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.verseny = response.data[0];
            vm.versenyAllapot = vm.verseny.allapot;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getIndulok() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getIndulok");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.indulok = response.data;
            vm.selected_indulok = vm.indulok.filter(function (item) {
              return !!item.user_rn;
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style>
</style>