<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title dense flat>{{ title }}</v-card-title>
      <v-card-text v-show="!!message" class="body-1">{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="alert">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">OK</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">Igen</v-btn>
        <v-btn color="grey" text @click.native="cancel">Nem</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

 

<script>
export default {
  name: "Confirm",
  props: {
    alert: Boolean,
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 360,
      zIndex: 200,
    },
  }),

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

 