<template>
  <v-sheet class="pa-3" height="calc(100vh - 75px)">
    <h1 class="green--text">Vesrenysorozatok</h1>
    <v-data-table
      dense
      hide-default-footer
      :headers="idoszakok_header"
      :items="idoszakok"
      v-model="selected_idoszak"
      item-key="recno"
      single-select
      @click:row="selectidoszak"
      class="row-pointer"
      height="calc(100vh - 175px)"
      :header-props="headerProps"
      :sort-by="['datum_tol']"
      :sort-desc="[true]"
    >
      <template v-slot:item.action="{ item }">
        <v-icon v-if="vanJoga('admin')" @click.stop="editidoszak(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="idoszakDialog" max-width="600">
      <v-card>
        <v-toolbar color="green" dark
          >{{ formTitle }}<v-spacer></v-spacer
          ><v-icon
            @click="
              idoszakDialog = false;
              formData = {};
            "
            >mdi-close</v-icon
          ></v-toolbar
        >
        <v-card-text>
          <v-form v-model="idoszakForm" class="mt-6" ref="f1">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  regular
                  label="A versenysorozat neve"
                  v-model="formData.megnevezes"
                  :rules="[kotelezoRule]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5" class="mr-0">
                <v-menu
                  v-model="menuDatumtol"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.datum_tol"
                      label="A versenysorozat kezdő napja"
                      :rules="[kotelezoRule, idoszakRule]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.datum_tol"
                    @input="menuDatumtol = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="5" class="mr-0">
                <v-menu
                  v-model="menuDatumig"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.datum_ig"
                      label="A versenysorozat utolsó napja"
                      :rules="[kotelezoRule, idoszakRule]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.datum_ig"
                    @input="menuDatumig = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="mentidoszak()" :disabled="!idoszakForm"
            >Ment</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-icon
      v-if="vanJoga('admin')"
      style="position: fixed; bottom: 20px; right: 15px"
      color="green"
      size="50px"
      @click="newidoszak()"
    >
      mdi-plus-circle-outline
    </v-icon>
    <error-dialog v-model="errorMessage"></error-dialog>
  </v-sheet>
</template>

<script>
import ErrorDialog from "./ErrorDialog.vue";

import axios from "axios";
//import moment from "moment";

export default {
  components: { ErrorDialog },
  name: "idoszakok",
  computed: {
    formTitle() {
      return this.idoszakFormAction == "new"
        ? "Új versenysorozat felvétele"
        : "A versenysorozat adatainak módosítása";
    },
    idoszakRule() {
      if (this.formData.datum_tol > this.formData.datum_ig) {
        return "Hibás időintervallum !!";
      }

      return true;
    },
  },
  data() {
    return {
      menuDatumtol: false,
      menuDatumig: false,
      idoszakForm: false,
      idoszakFormAction: "",
      idoszakDialog: false,
      errorMessage: "",
      idoszakok: [],
      selected_idoszak: [],
      formData: { datum_tol: "", datum_ig: "" },
      headerProps: { sortByText: "Rendezés" },
      idoszakok_header: [
        {
          text: "A versenysorozat neve",
          value: "megnevezes",
          class: "green--text subtitle-2",
        },

        {
          text: "A versenysorozat első napja",
          value: "datum_tol",
          class: "green--text subtitle-2",
        },
        {
          text: "A versenysorozat utolsó napja",
          value: "datum_ig",
          class: "green--text subtitle-2",
        },

        { text: "", value: "action", class: "green--text subtitle-2" },
      ],
    };
  },

  mounted() {
    this.getidoszakok();
  },

  methods: {
    kotelezoRule(v) {
      return !!v || "Kötelező kitölteni!";
    },
    mentidoszak() {
      let vm = this;
      let formData = new FormData();

      Object.entries(this.formData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("action", "mentIdoszak");

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.idoszakDialog = false;
            vm.formData = {};
            vm.idoszakok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    selectidoszak(item, row) {
      row.select();
    },
    newidoszak() {
      this.idoszakFormAction = "new";
      this.idoszakDialog = true;
      this.formData = {};
    },
    editidoszak(item) {
      // console.log(item);
      this.idoszakFormAction = "modi";
      this.idoszakDialog = true;
      Object.assign(this.formData, item);
    },

    getidoszakok() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getIdoszakok");
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.idoszakok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>