<template>
  <v-dialog :value="!!errorMessage" width="500">
    <v-card>
      <v-card-title class="headline red lighten-2">
        Hiba
        <v-spacer></v-spacer>
        <v-btn icon v-on:click="handleInput">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5 text-h6">
        {{ errorMessage }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

 

<script>
export default {
  name: "ErrorDialog",

  model: {
    prop: "errorMessage",
    event: "zar",
  },

  props: {
    errorMessage: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleInput() {
      this.$emit("zar", "");
    },
  },
};
</script>