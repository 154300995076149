<template>
  <v-sheet>
    <div
      v-for="tag in Vegeredmeny"
      :key="tag.recno"
      class="d-flex justify-center"
    >
      <v-card
        class="ma-2 elevation-5"
        :width="width(tag.helyezes)"
        hover
        outlined
      >
        <v-card-text>
          <v-row align="center" :class="betu(tag.helyezes)">
            <v-col cols="6" order="1" lg="2" xl="2">
              {{ tag.helyezes }}. / {{ tag.pont }}p</v-col
            >

            <v-col
              cols="12"
              order="3"
              lg="8"
              order-lg="2"
              xl="8"
              order-xl="2"
              class="d-flex justify-center text-center"
            >
              {{ tag.nev }}
            </v-col>
            <v-col
              cols="6"
              order="2"
              lg="2"
              order-lg="3"
              xl="2"
              order-xl="3"
              align="end"
              class="fill-height"
            >
              <v-avatar :size="avatar(tag.helyezes)">
                <img :src="kep(tag.recno)" />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import axios from "axios";

export default {
  name: "Vegeredmeny",
  props: {
    versenyAllapot: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Vegeredmeny: [],
    };
  },

  methods: {
    width(hely) {
      let size = "60%";
      if (hely == 1) {
        size = "90%";
      }
      if (hely == 2) {
        size = "80%";
      }
      if (hely == 3) {
        size = "70%";
      }
      return size;
    },
    betu(hely) {
      let size = parseInt(hely) + 1;
      if (hely < 4) {
        return "text-h" + size + " green--text";
      } else {
        return "text-h5 green--text";
      }
    },
    avatar(hely) {
      console.log(hely);
      let size = 30;
      if (hely == 1) {
        size = 80;
      }
      if (hely == 2) {
        size = 70;
      }
      if (hely == 3) {
        size = 60;
      }

      console.log(size);
      return size;
    },
    kep(recno) {
      return (
        "https://darts.laryon.hu/images/photos/" +
        recno +
        ".jpg" +
        "?" +
        new Date()
      );
    },
    getVegeredmeny() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getVegeredmeny");
      formData.append("verseny_rn", this.$store.state.selected_verseny);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.Vegeredmeny = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },

  mounted() {
    if (this.versenyAllapot == "vege") {
      this.getVegeredmeny();
    }
  },
  watch: {
    versenyAllapot(value) {
      if (value == "vege") {
        this.getVegeredmeny();
      }
    },
  },
};
</script>

<style scoped>
</style>