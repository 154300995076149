import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import ErrorDialog from "@/components/ErrorDialog.vue";
import Confirm from "@/components/Confirm.vue";
import DatetimePicker from "vuetify-datetime-picker";

Vue.component("ErrorDialog", ErrorDialog);
Vue.component("Confirm", Confirm);
Vue.use(DatetimePicker);

Vue.config.productionTip = false

import axios from "axios";

Vue.mixin({
  methods: {
    vanJoga(p_jogok) {
      let vm = this;
      let ok = false;
      let jogok = p_jogok.split(",");
      jogok.forEach(function (jog) {
        if (jog == vm.$store.state.jogok) {
          ok = true;
        }
      });
      return ok;
    },
    numberFormat(number, precision = 2) {
      return ((isNaN(number) || number == null || number == "") ? number : new Intl.NumberFormat('hu-HU', { minimumFractionDigits: precision }).format(parseFloat(number).toFixed(precision)));
    },
    downloadFromUrl(url) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.headers["content-type"] });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = url.substring(url.lastIndexOf('/') + 1);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

  }
});




new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
