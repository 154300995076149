<template>
  <div>
    <v-sheet class="pa-6">
      <h1 class="green--text">TOP lista</h1>
      <v-select
        :items="idoszakok"
        item-value="recno"
        item-text="megnevezes"
        label="Versenysorozat"
        v-model="selectedIdoszak"
        @change="getStat"
      >
      </v-select>
      <v-card height="calc(100vh - 240px)" style="overflow: scroll">
        <v-card-text>
          <v-card
            class="mt-2 elevation-6"
            hover
            v-for="tag in toplista"
            :key="tag.user_rn"
          >
            <v-card-text class="text-h5 green white--text pa-2">
              <v-row>
                <v-col> {{ tag.helyezes }}. {{ tag.nev }} </v-col>
                <v-col cols="2" align="end">{{ tag.pont }} </v-col></v-row
              >
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Stat",
  data() {
    return {
      idoszakok: [],
      toplista: [],
      selectedIdoszak: null,
    };
  },
  mounted() {
    this.getIdoszakok();
  },
  methods: {
    getStat() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getStat");
      formData.append("idoszak_rn", this.selectedIdoszak);
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.toplista = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getIdoszakok() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getIdoszakok");
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.idoszakok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

