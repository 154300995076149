<template>
  <v-sheet>
    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          background-color="green lighten-2"
          dark
          v-if="versenyAllapot != 'indulok'"
        >
          <v-tab v-for="csoport in csoportAllapotok" :key="csoport.csoport_id">
            <v-icon left v-if="csoportAllapot(csoport.csoport_id) == 1">
              mdi-check-bold
            </v-icon>
            <v-icon left v-else> mdi-clock-outline </v-icon>
            {{
              $store.state.toggle ? csoport.csoport_id : csoport.sorrend
            }}.csoport
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="csoport in csoportAllapotok"
              :key="csoport.csoport_id"
            >
              <v-card flat style="height: calc(100vh - 255px); overflow: auto">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="5" xl="5">
                      <span class="green--text subtitle-1">Játékosok</span>
                      <v-row class="text-center">
                        <v-col cols="2" offset="4">Pont</v-col>
                        <v-col cols="2">Leg<br />arány</v-col>
                        <v-col cols="2">Győztes<br />legek</v-col>
                        <v-col cols="2">Vesztes<br />legek</v-col>
                      </v-row>
                      <v-card
                        v-for="jatekos in csoportJatekosok(csoport.csoport_id)"
                        :key="jatekos.recno"
                        class="mt-2"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="4">
                              {{ jatekos.sorszam }}.
                              {{ jatekos.nev }}
                            </v-col>

                            <v-col cols="2" class="text-center">
                              {{ jatekos.pont }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              {{ jatekos.legs }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              {{ jatekos.gylegs }}
                            </v-col>
                            <v-col cols="2" class="text-center">
                              {{ jatekos.vlegs }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-row class="mt-6">
                        <v-col cols="4">
                          <v-text-field
                            class="ml-5"
                            outlined
                            dense
                            label="Nyert leg szám"
                            v-model="nyertleg[csoport.csoport_id]"
                            @change="nyertLegTarol(csoport.csoport_id)"
                            :rules="[numberRule]"
                            :disabled="
                              csoportAllapot(csoport.csoport_id) != 0 ||
                              !vanJoga('admin')
                            "
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-btn
                        v-if="
                          csoportAllapot(csoport.csoport_id) == 0 &&
                          vanJoga('admin')
                        "
                        text
                        color="green"
                        class="mt-8"
                        @click="csoportZar(csoport.csoport_id)"
                      >
                        {{
                          $store.state.toggle
                            ? csoport.csoport_id
                            : csoport.sorrend
                        }}.csoport versenyények lezárása
                      </v-btn>
                      <v-row v-if="csoportAllapot(csoport.csoport_id) == 1">
                        <v-col class="text-h6 mt-6 red--text ml-5">
                          Ez a csoportverseny lezárult
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                      <span class="green--text subtitle-1">Meccsek</span>

                      <v-card
                        v-for="meccs in meccsek(csoport.csoport_id)"
                        :key="meccs.recno + 1"
                        class="mt-2"
                      >
                        <v-card-text @click="eredmeny(meccs)" class="pointer">
                          <v-row>
                            <v-col>
                              <span style="font-size: 12pt"
                                >{{ meccs.jatekos1 }} -
                                {{ meccs.jatekos2 }}</span
                              >
                              <br />
                              ({{ meccs.iro }})
                            </v-col>
                            <v-col cols="2" class="text-right text-h6">
                              {{ meccs.jatekos1_eredmeny }}
                            </v-col>
                            <v-col cols="1" class="text-center text-h6">
                              :
                            </v-col>
                            <v-col cols="2" class="text-left text-h6">
                              {{ meccs.jatekos2_eredmeny }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                      <span class="green--text subtitle-1">Sorrend</span>
                      <v-card
                        v-for="(jatekos, index) in csoportSorrend(
                          csoport.csoport_id
                        )"
                        :key="jatekos.recno"
                        class="mt-2"
                        :color="color_(index)"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              {{ jatekos.sorszam }}.
                              {{ jatekos.nev }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
    <v-dialog v-model="eredmenyDialog" max-width="600">
      <v-card>
        <v-toolbar color="green" dark>
          Eredmény megadása<v-spacer> </v-spacer>
          <v-icon @click="eredmenyDialog = false">mdi-close</v-icon></v-toolbar
        >
        <v-card-text class="pt-5 text-center">
          <v-row>
            <v-col class="text-h5">{{ selectedMeccs.jatekos1 }}</v-col>
            <v-col class="text-h5">{{ selectedMeccs.jatekos2 }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="4" offset="1" sm="2" offset-sm="2">
              <input
                v-model="selectedMeccs.jatekos1_eredmeny"
                class="er"
                @change="mentEredmeny()"
                onfocus="this.select();"
                type="number"
              />
            </v-col>

            <v-col cols="4" offset="2" sm="2" offset-sm="4">
              <input
                v-model="selectedMeccs.jatekos2_eredmeny"
                class="er"
                @change="mentEredmeny()"
                onfocus="this.select();"
                type="number"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ErrorDialog v-model="errorMessage"></ErrorDialog>
  </v-sheet>
</template>

<script>
import axios from "axios";

export default {
  name: "CsoportVerseny",
  props: {
    csoportszam: {
      type: Number,
      required: true,
    },
    induloszam: {
      type: Number,
      required: true,
    },
    kijuto: {
      type: Number,
      required: true,
    },
    versenyAllapot: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nyertleg: [],
      tab: null,
      csoportAllapotok: null,
      csoportVerseny: [],
      csoportMerkozesek: [],
      eredmenyDialog: false,
      selectedMeccs: {},
      sorrend: [],
      errorMessage: "",
      csoportIDsorrend: [],
      besorolas: [
        {
          min: 8,
          max: 8,
          csoport: 2,
          kijuto: 2,
          fotabla: 4,
          tabla: ["1/1", "2/2", "2/1", "1/2"],
        },
        {
          min: 9,
          max: 9,
          csoport: 2,
          kijuto: 3,
          fotabla: 8,
          tabla: ["1/1", "", "2/2", "1/3", "2/1", "", "1/2", "2/3"],
        },
        {
          min: 10,
          max: 12,
          csoport: 2,
          kijuto: 4,
          fotabla: 8,
          tabla: ["1/1", "2/4", "2/2", "1/3", "2/1", "1/4", "1/2", "2/3"],
        },
        {
          min: 13,
          max: 16,
          csoport: 4,
          kijuto: 2,
          fotabla: 8,
          tabla: ["1/1", "2/2", "4/1", "3/2", "2/1", "1/2", "3/1", "4/2"],
        },
        {
          min: 17,
          max: 19,
          csoport: 4,
          kijuto: 3,
          fotabla: 16,
          tabla: [
            "1/1",
            "",
            "2/2",
            "3/3",
            "4/1",
            "",
            "3/2",
            "2/3",
            "2/1",
            "",
            "1/2",
            "4/3",
            "3/1",
            "",
            "4/2",
            "1/3",
          ],
        },
        {
          min: 20,
          max: 24,
          csoport: 4,
          kijuto: 4,
          fotabla: 16,
          tabla: [
            "1/1",
            "4/4",
            "2/2",
            "3/3",
            "4/1",
            "1/4",
            "3/2",
            "2/3",
            "2/1",
            "3/4",
            "1/2",
            "4/3",
            "3/1",
            "2/4",
            "4/2",
            "1/3",
          ],
        },
        {
          min: 25,
          max: 32,
          csoport: 8,
          kijuto: 2,
          fotabla: 16,
          tabla: [
            "1/1",
            "2/2",
            "8/1",
            "7/2",
            "4/1",
            "3/2",
            "5/1",
            "6/2",
            "2/1",
            "1/2",
            "7/1",
            "8/2",
            "3/1",
            "4/2",
            "6/1",
            "5/2",
          ],
        },
        {
          min: 33,
          max: 39,
          csoport: 8,
          kijuto: 3,
          fotabla: 32,
          tabla: [
            "1/1",
            "",
            "2/2",
            "3/3",
            "8/1",
            "",
            "7/2",
            "6/3",
            "4/1",
            "",
            "3/2",
            "2/3",
            "5/1",
            "",
            "6/2",
            "7/3",
            "2/1",
            "",
            "1/2",
            "4/3",
            "7/1",
            "",
            "8/2",
            "5/3",
            "3/1",
            "",
            "4/2",
            "1/3",
            "6/1",
            "",
            "5/2",
            "8/3",
          ],
        },
        {
          min: 40,
          max: 48,
          csoport: 8,
          kijuto: 4,
          fotabla: 32,
          tabla: [
            "1/1",
            "4/4",
            "2/2",
            "3/3",
            "8/1",
            "5/4",
            "7/2",
            "6/3",
            "4/1",
            "1/4",
            "3/2",
            "2/3",
            "5/1",
            "8/4",
            "6/2",
            "7/3",
            "2/1",
            "3/4",
            "1/2",
            "4/3",
            "7/1",
            "6/4",
            "8/2",
            "5/3",
            "3/1",
            "2/4",
            "4/2",
            "1/3",
            "6/1",
            "7/4",
            "5/2",
            "8/3",
          ],
        },
        {
          min: 49,
          max: 64,
          csoport: 16,
          kijuto: 2,
          fotabla: 32,
          tabla: [
            "1/1",
            "2/2",
            "16/1",
            "15/2",
            "8/1",
            "7/2",
            "9/1",
            "10/2",
            "4/1",
            "3/2",
            "13/1",
            "14/2",
            "5/1",
            "6/2",
            "12/1",
            "11/2",
            "2/1",
            "1/2",
            "15/1",
            "16/2",
            "7/1",
            "8/2",
            "10/1",
            "9/2",
            "3/1",
            "4/2",
            "14/1",
            "13/2",
            "6/1",
            "5/2",
            "11/1",
            "12/2",
          ],
        },
        {
          min: 65,
          max: 79,
          csoport: 16,
          kijuto: 3,
          fotabla: 64,
          tabla: [
            "1/1",
            "",
            "2/2",
            "3/3",
            "16/1",
            "",
            "15/2",
            "14/3",
            "8/1",
            "",
            "7/2",
            "6/3",
            "9/1",
            "",
            "10/2",
            "11/3",
            "4/1",
            "",
            "3/2",
            "2/3",
            "13/1",
            "",
            "14/2",
            "15/3",
            "5/1",
            "",
            "6/2",
            "7/3",
            "12/1",
            "",
            "11/2",
            "10/3",
            "2/1",
            "",
            "1/2",
            "4/3",
            "15/1",
            "",
            "16/2",
            "13/3",
            "7/1",
            "",
            "8/2",
            "12/3",
            "10/1",
            "",
            "9/2",
            "5/3",
            "3/1",
            "",
            "4/2",
            "1/3",
            "14/1",
            "",
            "13/2",
            "16/3",
            "6/1",
            "",
            "5/2",
            "9/3",
            "11/1",
            "",
            "12/2",
            "8/3",
          ],
        },
      ],
    };
  },

  methods: {
    nyertLegTarol(csoport) {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "nyertLegTarol");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("csoport_id", csoport);
      formData.append("nyertleg", this.nyertleg[csoport]);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    kotelezoRule(v) {
      return !!v || "Kötelező kitölteni!";
    },
    numberRule(v) {
      if (v) {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 2 && v <= 10) return true;
        return "Csak 2 és 10 közötti szám lehet!";
      } else {
        return true;
      }
    },

    csoportAllapot(csoport) {
      if (this.csoportAllapotok) {
        let cs = this.csoportAllapotok.find(function (item) {
          return item.csoport_id == csoport;
        });
        if (cs) {
          if (cs.allapot == 1) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return -1;
      }
    },

    getCsoportAllapot() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getCsoportAllapot");
      formData.append("verseny_rn", this.$store.state.selected_verseny);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.csoportAllapotok = response.data;
            if (vm.csoportAllapotok.length > 0) {
              let mindzart = true;
              vm.csoportAllapotok.forEach(function (item) {
                vm.nyertleg[item.csoport_id] = item.nyertleg;
                if (item.allapot == 0) {
                  mindzart = false;
                }
              });
              if (mindzart && vm.versenyAllapot == "csoportVerseny") {
                vm.allapotValt("fotabla");
              }
            }
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    allapotValt(alapot) {
      this.$emit("allapotValt", alapot);
    },
    fotablaHely(csoport, helyezes) {
      let vm = this;
      let fotabla = this.besorolas.find(function (item) {
        return vm.induloszam >= item.min && vm.induloszam <= item.max;
      });
      let fotablaHely = fotabla.tabla.findIndex(function (item) {
        return item == csoport + "/" + helyezes;
      });
      return fotablaHely;
    },
    fotabla_tarol(user_rn, tabla_ssz, jatekos) {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "fotablaTarol");
      formData.append("verseny_rn", this.$store.state.selected_verseny);
      formData.append("user_rn", user_rn);
      formData.append("tabla_ssz", tabla_ssz);
      formData.append("jatekos", jatekos);
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    ellenoriz(csoport) {
      let vm = this;
      let ok = true;
      if (!vm.nyertleg[csoport]) {
        this.errorMessage = "A nyert legek számát előbb meg kell adni!!";
        return;
      }
      this.csoportMerkozesek
        .filter(function (item) {
          return item.csoport_id == csoport;
        })
        .forEach(function (item) {
          //console.log(item);
          //console.log(1);
          if (
            item.jatekos1_eredmeny != vm.nyertleg[csoport] &&
            item.jatekos2_eredmeny != vm.nyertleg[csoport]
          ) {
            vm.errorMessage =
              "A meccsek " + vm.nyertleg[csoport] + " nyert leg-ig tartanak!! ";
            ok = false;
            return;
          }
          //    console.log(2);
          if (
            item.jatekos1_eredmeny > vm.nyertleg[csoport] ||
            item.jatekos2_eredmeny > vm.nyertleg[csoport]
          ) {
            vm.errorMessage =
              "A meccsek " + vm.nyertleg[csoport] + " nyert leg-ig tartanak!! ";
            ok = false;
            return;
          }
          //  console.log(3);
          if (item.jatekos1_eredmeny == item.jatekos2_eredmeny) {
            vm.errorMessage = "Döntetlen meccs nem lehet!!";
            ok = false;
            return;
          }
        });
      return ok;
    },

    csoportZar(csoport) {
      if (this.ellenoriz(csoport)) {
        for (let i = 1; i <= this.kijuto; i++) {
          let kijut = this.csoportSorrend(csoport)[i - 1];
          let tabla_ssz = this.fotablaHely(csoport, i);
          this.fotabla_tarol(
            kijut.user_rn,
            Math.floor(tabla_ssz / 2) + 1,
            (tabla_ssz % 2) + 1
          );
        }

        let vm = this;
        let formData = new FormData();

        formData.append("action", "csoportZar");
        formData.append("verseny_rn", this.$store.state.selected_verseny);
        formData.append("csoport_id", csoport);

        axios
          .post("https://darts.laryon.hu/backend/data.php", formData)
          .then(function (response) {
            if (response.data.error) {
              vm.errorMessage = response.data.error;
            } else {
              vm.getCsoportAllapot();
              vm.$store.state.rebuild++;
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    color_(index) {
      if (index < this.kijuto) {
        return "rgba(0,255,0,0.1)";
      }
      return "";
    },
    csoportJatekosok(csoport) {
      return this.csoportVerseny.filter(function (item) {
        return item.csoport_id == csoport;
      });
    },
    csoportSorrend(csoport) {
      let vm = this;
      return this.csoportJatekosok(csoport).sort(function (a, b) {
        if (parseInt(a.pont) > parseInt(b.pont)) {
          return -1;
        }
        if (parseInt(a.pont) < parseInt(b.pont)) {
          return 1;
        }

        if (parseInt(a.legs) > parseInt(b.legs)) {
          return -1;
        }
        if (parseInt(a.legs) < parseInt(b.legs)) {
          return 1;
        }
        if (vm.csoportMerkozesek) {
          let egymaselleni = vm.csoportMerkozesek.find(function (item) {
            if (
              (item.jatekos1_rn == a.user_rn &&
                item.jatekos2_rn == b.user_rn) ||
              (item.jatekos1_rn == b.user_rn && item.jatekos2_rn == a.user_rn)
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (egymaselleni) {
            if (
              (egymaselleni.jatekos1_rn == a.user_rn &&
                egymaselleni.jatekos1_eredmeny >
                  egymaselleni.jatekos2_eredmeny) ||
              (egymaselleni.jatekos2_rn == a.user_rn &&
                egymaselleni.jatekos1_eredmeny < egymaselleni.jatekos2_eredmeny)
            ) {
              return -1;
            }

            return 1;
          }
        }
        return 0;
      });
    },
    mentEredmeny() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "eredmenyMent");
      formData.append("merkozes_recno", this.selectedMeccs.recno);
      formData.append(
        "jatekos1_eredmeny",
        this.selectedMeccs.jatekos1_eredmeny
      );
      formData.append(
        "jatekos2_eredmeny",
        this.selectedMeccs.jatekos2_eredmeny
      );
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.csoportVerseny = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    eredmeny(meccs) {
      if (this.csoportAllapot(meccs.csoport_id) == 0 && this.vanJoga("admin")) {
        this.eredmenyDialog = true;
        this.selectedMeccs = meccs;
      }
    },
    meccsek(csoport) {
      return this.csoportMerkozesek.filter(function (item) {
        return item.csoport_id == csoport;
      });
    },

    jatekosszam(i) {
      let nagycsoport = this.induloszam % this.csoportszam;
      let kiscsoport = this.csoportszam - nagycsoport;
      let csoportletszam = (this.induloszam - nagycsoport) / this.csoportszam;
      if (i <= kiscsoport) {
        return csoportletszam;
      } else {
        return csoportletszam + 1;
      }
    },

    getCsoportmerkozesek() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getCsoportmerkozesek");
      formData.append("verseny_rn", this.$store.state.selected_verseny);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.csoportMerkozesek = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    getCsoportVerseny() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getCsoportVerseny");
      formData.append("induloszam", this.induloszam);
      formData.append("csoportszam", this.csoportszam);
      formData.append("verseny_rn", this.$store.state.selected_verseny);

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.csoportVerseny = response.data;
            vm.getCsoportmerkozesek();
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },

  mounted() {
    if (this.versenyAllapot != "indulok") {
      this.getCsoportVerseny();
      this.getCsoportAllapot();
    }
  },
  watch: {
    versenyAllapot(value) {
      // console.log(value);
      if (value != "indulok") {
        this.getCsoportVerseny();
        this.getCsoportAllapot();
      }
    },
  },
};
</script>

<style scoped>
.parent {
  display: grid;
  grid-template-columns: 200px 50px 50px 50px 50px 50px 50px;
  grid-template-rows: 200px 50px 50px 50px 50px 50px 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 800 px;
  height: 800 px;
}
.child {
  width: 100%;
  height: 100%;
  background-color: blanchedalmond;
}
.er {
  width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 30px;
  text-align: center;
  border: 1px solid rgb(145, 143, 143);
  border-radius: 5px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pointer:hover {
  cursor: pointer;
}
</style>