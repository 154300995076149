<template>
  <v-sheet class="pa-3" height="calc(100vh - 75px)">
    <h1 class="green--text">Versenyek</h1>
    <v-data-table
      dense
      hide-default-footer
      :headers="versenyek_header"
      :items="versenyek"
      v-model="selected_verseny"
      item-key="recno"
      single-select
      @click:row="selectVerseny"
      class="row-pointer"
      height="calc(100vh - 175px)"
      :header-props="headerProps"
    >
      <template v-slot:item.action="{ item }">
        <v-icon v-if="vanJoga('admin')" @click.stop="editVerseny(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="versenyDialog" max-width="600" persistent>
      <v-card>
        <v-toolbar color="green" dark
          >{{ formTitle }}<v-spacer></v-spacer
          ><v-icon
            @click="
              versenyDialog = false;
              formData = {};
            "
            >mdi-close</v-icon
          ></v-toolbar
        >
        <v-card-text>
          <v-form v-model="versenyForm" class="mt-6" ref="f1">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  regular
                  label="A verseny neve"
                  v-model="formData.megnevezes"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  regular
                  label="Helyszín"
                  v-model="formData.helyszin"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mr-0">
                <v-menu
                  v-model="menuDatum"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.datum"
                      label="A verseny napja"
                      :rules="[(value) => !!value || 'Kötelező kitölteni!']"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.datum"
                    @input="menuDatum = false"
                    @change="getidoszakok()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="ml-0">
                <v-menu
                  ref="menu"
                  v-model="menuTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="formData.datum_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.idopont"
                      label="Időpont"
                      readonly
                      :rules="[kotelezoRule]"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-if="menuTime"
                    v-model="formData.idopont"
                    full-width
                    @click:minute="$refs.menu.save(formData.idopont)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-menu
                  v-model="menuJelentkezesi"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.jelentkezesi_hatarido"
                      label="Jelentkezési határidő"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.jelentkezesi_hatarido"
                    @input="menuJelentkezesi = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-select
                  :items="tagok"
                  label="Szervező"
                  v-model="formData.szervezo"
                  item-text="nev"
                  item-value="recno"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  regular
                  label="Megjegyzés"
                  v-model="formData.megjegyzes"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="idoszakok"
                  label="Versenysorozat"
                  v-model="formData.idoszak_rn"
                  item-text="megnevezes"
                  item-value="recno"
                  clearable
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="mentVerseny()" :disabled="!versenyForm"
            >Ment</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-icon
      v-if="vanJoga('admin')"
      style="position: fixed; bottom: 20px; right: 15px"
      color="green"
      size="50px"
      @click="newVerseny()"
    >
      mdi-plus-circle-outline
    </v-icon>
    <error-dialog v-model="errorMessage"></error-dialog>
  </v-sheet>
</template>

<script>
import ErrorDialog from "./ErrorDialog.vue";

import axios from "axios";
//import moment from "moment";

export default {
  components: { ErrorDialog },
  name: "Versenyek",
  computed: {
    formTitle() {
      return this.versenyFormAction == "new"
        ? "Új verseny felvétele"
        : "Verseny adatainak módosítása";
    },
  },
  data() {
    return {
      idoszakok: [],
      menuTime: false,
      menuDatum: false,
      menuJelentkezesi: false,
      dateProps: {
        headerColor: "green",
        color: "green lighten-1",
      },
      timeProps: {
        headerColor: "green",
        color: "green lighten-1",
        format: "24hr",
      },
      tagok: [],
      versenyForm: false,
      versenyFormAction: "",
      versenyDialog: false,
      errorMessage: "",
      versenyek: [],
      selected_verseny: [],
      formData: {},
      headerProps: { sortByText: "Rendezés" },
      versenyek_header: [
        {
          text: "A verseny neve",
          value: "megnevezes",
          class: "green--text subtitle-2",
        },
        {
          text: "Helyszín",
          value: "helyszin",
          class: "green--text subtitle-2",
        },
        {
          text: "A veseny napja",
          value: "datum",
          class: "green--text subtitle-2",
        },
        { text: "Kezdés", value: "idopont", class: "green--text subtitle-2" },
        {
          text: "Jelentkezesi határidő",
          value: "jelentkezesi_hatarido",
          class: "green--text subtitle-2",
        },
        {
          text: "Szervező",
          value: "szervezo_",
          class: "green--text subtitle-2",
        },
        {
          text: "Megjegyzés",
          value: "megjegyzes",
          class: "green--text subtitle-2",
        },
        { text: "Állapot", value: "allapot_", class: "green--text subtitle-2" },
        { text: "", value: "action", class: "green--text subtitle-2" },
      ],
    };
  },

  mounted() {
    this.getVersenyek();
    this.getTagok();
  },

  methods: {
    kotelezoRule(v) {
      return !!v || "Kötelező kitölteni!";
    },
    getidoszakok() {
      if (this.formData.datum) {
        let vm = this;
        let formData = new FormData();

        formData.append("action", "getIdoszakok");
        formData.append("datum", this.formData.datum);
        axios
          .post("https://darts.laryon.hu/backend/data.php", formData)
          .then(function (response) {
            if (response.data.error) {
              vm.errorMessage = response.data.error;
            } else {
              vm.errorMessage = "";
              vm.idoszakok = response.data;
              if (vm.formData.idoszak_rn) {
                if (
                  !vm.idoszakok.find(function (item) {
                    return item.recno == vm.formData.idoszak_rn;
                  })
                ) {
                  vm.formData.idoszak_rn = null;
                }
              }
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    getTagok() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getTagok");
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.tagok = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    mentVerseny() {
      let vm = this;
      let formData = new FormData();

      Object.entries(this.formData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("action", "mentVerseny");

      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.versenyDialog = false;
            vm.formData = {};
            vm.versenyek = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    selectVerseny(item, row) {
      row.select();
      this.$store.state.selected_verseny = item.recno;
      this.$store.state.verseny = item;
      this.$router.push({ name: "verseny" });
    },
    newVerseny() {
      this.idoszakok = [];
      this.versenyFormAction = "new";
      this.versenyDialog = true;
      this.formData = {};
    },
    editVerseny(item) {
      // console.log(item);
      this.versenyFormAction = "modi";
      this.versenyDialog = true;
      Object.assign(this.formData, item);
      this.getidoszakok();
    },

    getVersenyek() {
      let vm = this;
      let formData = new FormData();

      formData.append("action", "getVersenyek");
      axios
        .post("https://darts.laryon.hu/backend/data.php", formData)
        .then(function (response) {
          if (response.data.error) {
            vm.errorMessage = response.data.error;
          } else {
            vm.errorMessage = "";
            vm.versenyek = response.data;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>