<template>
  <v-container>
    <div style="height: calc(30vh)"></div>
    <v-row>
      <v-col> </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <h1
          class="font-weight-bold"
          style="font-size: 6rem; letter-spacing: 3px"
        >
          Pécsi Darts Club
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
