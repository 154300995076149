import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import hu from 'vuetify/lib/locale/hu';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { hu },
        current: 'hu',
    },
    theme: {
        themes: {
            light: {
                primary: '#4CAF50',
                secondary: '#009688',
                accent: '#795548',
                error: '#ff5722',
                warning: '#ff9800',
                info: '#00bcd4',
                success: '#4caf50'
            }
        }
    }
});
